<template>
  <div>
    <template v-for="asset in multiselectedAssets">
      <InfoPanelSingle :asset="asset" />
    </template>
    <InfoPanelEmpty v-if="multiselectedAssets.length < 1" />
  </div>
</template>

<script>
const InfoPanelSingle = () => import('./assets-explorer-info-panel/assets-explorer-info-panel-single.vue')
const InfoPanelEmpty = () => import('./assets-explorer-info-panel/assets-explorer-info-panel-empty.vue')

export default {

  components: {
    'InfoPanelSingle': InfoPanelSingle,
    'InfoPanelEmpty': InfoPanelEmpty
  },

  props: {

    multiselectedAssets: {
      type: Array,
      required: true
    }
  }

}

</script>
